/* React */
import React, { useState } from "react";

/* Utility Functions */
import emailjs from "@emailjs/browser";

/* Styling */
import "./ContactForm.css";
import Spinner from "../../../Common/Spinner/LoadingSpinner";

interface FAQFormData {
  fullName: string;
  email: string;
  message: string;
}

const emailJSPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;
const emailJSServiceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
const emailJSTemplateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;

const FAQContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FAQFormData>({
    fullName: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage("");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");

    console.log("Public Key:", emailJSPublicKey);
    console.log("Service ID:", emailJSServiceID);
    console.log("Template ID:", emailJSTemplateID);

    // TODO: create better alerts of sending email
    emailjs
      .sendForm(
        emailJSServiceID,
        emailJSTemplateID,
        e.target as HTMLFormElement,
        emailJSPublicKey
      )
      .then(
        () => {
          setFormData({ fullName: "", email: "", message: "" });
          setMessage("Your message has been sent successfully!");
          setIsLoading(false);
        },
        (e) => {
          console.error("Email sending error: ", e.text);
          setMessage(
            "An error occurred while sending your message. Please try again later."
          );
          setFormData({ fullName: "", email: "", message: "" });
          setIsLoading(false);
        }
      );
  };

  return (
    <div>
      {/* Desktop Version */}
      <div className="faq-contact-form-container">
        <div className="faq-contact-form-content-left">
          <div className="faq-contact-form-header">Can't find your answer?</div>

          <div className="faq-contact-form-subtext">
            Fill the form and support will reach out shortly!
          </div>
        </div>

        <div className="faq-contact-form-content-right">
          <form onSubmit={handleSubmit} className="faq-contact-form">
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              className="faq-contact-form-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              className="faq-contact-form-input"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              className="faq-contact-form-textarea"
              required
            />

            <div className="contact-form-message">
              {message && (
                <p className="contact-form-message__alert">{message}</p>
              )}
            </div>

            <div className="faq-contact-form__controls">
              {isLoading ? (
                <div className=" loading-spinner loading-spinner--small">
                  <Spinner />
                </div>
              ) : (
                <button className="faq-contact-form-submit-button">
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="faq-contact-mobile-form-container">
        <div className="faq-contact-form-mobile-top">
          <div className="faq-contact-form-mobile-header">
            Can't find your answer?
          </div>

          <div className="faq-contact-form-mobile-subtext">
            Fill the form and support will reach out shortly!
          </div>
        </div>

        <div className="faq-contact-form-mobile-bottom">
          <form onSubmit={handleSubmit} className="faq-contact-form-mobile">
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              className="faq-contact-form-mobile-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              className="faq-contact-form-mobile-input"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              className="faq-contact-form-mobile-textarea"
              required
            />

            <div className="contact-form-message">
              {message && (
                <p className="contact-form-message__alert">{message}</p>
              )}
            </div>

            <div className="faq-contact-form__controls">
              {isLoading ? (
                <div className=" loading-spinner loading-spinner--small">
                  <Spinner />
                </div>
              ) : (
                <button
                  type="submit"
                  className="faq-contact-form-mobile-submit-button"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FAQContactForm;
