/* React */
import React, { useState, useEffect, useRef } from "react";

/* Testimonials*/
import { testimonials } from "../testimonials";

/* Styling */
import "../Landing.css";
import "./MobileAgentReviews.css";

const MobileAgentReviews = () => {
  const [currentTestimonialIndex,  setCurrentTestimonialIndex] = useState(0);
  const totalTestimonials = testimonials.length;
  const lightbarRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % totalTestimonials);
    }, 3000);

    return () => clearInterval(interval);
  }, [totalTestimonials]);

  useEffect(() => {
    if (lightbarRef.current) {
      const lightbarWidth = lightbarRef.current.parentElement?.offsetWidth || 0;
      const slotWidth = lightbarWidth / totalTestimonials;
      lightbarRef.current.style.transform = `translateX(${
        currentTestimonialIndex * slotWidth
      }px)`;
    }
  }, [currentTestimonialIndex, totalTestimonials]);

  return (
    <div className="landing-mobile-agent-reviews">
      <div className="landing-mobile-agent-reviews-card">
        <div className="landing-mobile-agent-reviews-card-message">
          {testimonials[currentTestimonialIndex].message}
        </div>
        <div className="landing-mobile-agent-reviews-card-profile">
          <img
            src={testimonials[currentTestimonialIndex].profileImage}
            alt="Agent Profile"
            className="landing-mobile-agent-reviews-card-profile-image"
          />
          <div className="landing-mobile-agent-reviews-card-profile-info">
            <strong>{testimonials[currentTestimonialIndex].name}</strong>
            <div>{testimonials[currentTestimonialIndex].title}</div>
          </div>
        </div>
      </div>
      <div className="landing-mobile-agent-reviews-lightbar-container">
        <div
          ref={lightbarRef}
          className="landing-mobile-agent-reviews-lightbar"
        ></div>
      </div>
    </div>
  );
};

export default MobileAgentReviews;
