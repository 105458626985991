import JPMordiAgentProfile from "../../../assets/jp-moridi-testimony-photo.svg";
import ToddWalkerAgentProfile from "../../../assets/todd-walker-testimony-photo.svg";
import KiaKalhoriAgentProfile from "../../../assets/kia-kalhori-testimony-photo.svg";
import AnnaMartinAgentProfile from "../../../assets/anna-martin-testimony-photo.svg";

export const testimonials = [
    {
      message:
        '"Fast, accurate, and presented in a nice way. Agnsy is revolutionary"',
      profileImage: JPMordiAgentProfile,
      name: "JP Moridi",
      title: "Partner, Keller Williams Luxury",
    },
    {
      message:
        '"Our clients love the share feature and digestibility of the content"',
      profileImage: ToddWalkerAgentProfile,
      name: "Todd Walker",
      title: "Founder, Walker Team @ Compass",
    },
    {
      message:
        '"I could have saved weeks of time if I had this from the beginning!"',
      profileImage: KiaKalhoriAgentProfile,
      name: "Kia Kalhori",
      title: "Founder, Agnsy | $50M+ RE Sales",
    },
    {
      message:
        '"They have done something special here. I am never going back!"',
      profileImage: AnnaMartinAgentProfile,
      name: "Anna Martin",
      title: "WSJ Recognized Agent, Compass",
    },
  ];