/* React */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* Data Models */
import { UserPhone } from "../../../../model/DisclosureAnalysis/UserPhone";

/* UI Components */
import Navbar from "../../../Common/Navbar";
import PhoneInput from "react-phone-number-input";
import ErrorCircle from "../../../../assets/error_circle.svg";
import Spinner from "../../../Common/Spinner/LoadingSpinner";

/* Functionality */
import { postWithAuth } from "../../../../firebase/authentication/auth";
import { parsePhoneNumberFromString } from "libphonenumber-js";

/* Styling */
import "../auth.css";
import "./SendPhoneVerificationCode.css";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function SendPhoneVerificationCode() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disableSubmission, setDisableSubmission] = useState<boolean>(true);
  const [phoneData, setPhoneData] = useState<UserPhone | null>(null);
  const navigate = useNavigate();

  const actionTitles: { [key: string]: string } = {
    "forgot-password": "Forgot Password",
    "forgot-username": "Forgot Username",
    "account-verify": "Verify Account"
  };

  const title = action ? actionTitles[action] ?? "" : "";
  
  useEffect(() => {
    if (!action) {
      navigate("/"); 
    }
  }, [action, navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const uploadData = new FormData();
    uploadData.append("phone", phoneNumber);

    setPhoneData({ ...phoneData, number: phoneNumber } as UserPhone);
    setIsLoading(false);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      setDisableSubmission(true);
      return "Invalid phone number";
    }
    setDisableSubmission(false);
    return "";
  };

  const validateForm = (value: string) => {
    let error = "";

    error = validatePhoneNumber(value);
    setErrorMessage(error);
  };

  const sendPhoneCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    if (!phoneData) {
      return;
    }

    try {
      let message = "";
      switch (action) {
        case "account-verify":
          message = "ok";
          break;
        case "forgot-password":
          message = await sendForgotPasswordCode(phoneNumber);
          break;
        case "forgot-username":
          message = await sendForgotUsernameCode(phoneNumber);
          break;
        default:
          message = "Error. Please try again.";
      }

      if (message === "ok") {
        setIsLoading(false);
        navigate(
          `/verify-phone?phone=${encodeURIComponent(
            phoneNumber
          )}&action=${action}`
        );
      }
      setErrorMessage(message);
    } catch (error) {
      console.error("Error sending verification code:", error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Navbar />
      {phoneData ? (
        <div className="auth auth--send-verification-code">
          <form className="auth__form auth__form--send-verification-code__form">
            <h2 className="auth__title auth__title--send-verification-code__title">
              Access to certain content requires phone verification
            </h2>

            <div className="send-verification-code__message">
              A verification code will be sent to:
              <div className="send-verification-code__phone-number">
                {phoneData.number}
              </div>
            </div>

            <div className="auth__error-message auth__error-message--send-verification-code">
              {errorMessage && (
                <p className="auth__alert auth__alert--send-verification-code">
                  <img
                    src={ErrorCircle}
                    alt="Error Icon"
                    className="auth__error-icon auth__error-icon--send-verification-code"
                  />
                  {errorMessage}
                </p>
              )}
            </div>

            <div className="auth__controls auth__controls--send-verification-code">
              {isLoading ? (
                <div className="loading-spinner loading-spinner--small">
                  <Spinner />
                </div>
              ) : (
                <button
                  onClick={sendPhoneCode}
                  className="auth__button auth__botton--send-verification-code"
                >
                  Send code
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="auth auth--forgot-page">
          <h2 className="auth__title auth__title--forgot-page">{title} </h2>

          <form
            className="auth__form auth__form--forgot-page"
            onSubmit={handleSubmit}
          >
            <div className="forgot-page__message">Enter your phone number</div>

            <div className="auth__inputs auth__inputs--forgot-page">
              <PhoneInput
                international
                value={phoneNumber}
                defaultCountry="US"
                onChange={(value: string | undefined) => {
                  setPhoneNumber(value || "");
                  validateForm(value || "");
                }}
                required
                placeholder="Phone Number"
              />
            </div>

            <div className="auth__error-message auth__error-message--forgot-page">
              {errorMessage && (
                <p className="auth__alert auth__alert--forgot-page">
                  <img
                    src={ErrorCircle}
                    alt="Error Icon"
                    className="auth__error-icon auth__error-icon--forgot-page"
                  />
                  {errorMessage}
                </p>
              )}
            </div>

            <div className="auth__controls auth__controls--forgot-page">
              {isLoading ? (
                <div className="loading-spinner loading-spinner--small">
                  <Spinner />
                </div>
              ) : (
                <button
                  type="submit"
                  className={`auth__button auth__botton--forgot-page ${
                    disableSubmission ? "auth__button--disabled" : ""
                  }`}
                  disabled={disableSubmission}
                >
                  Continue
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export async function sendVerifyAccountCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/phone/send-verification-code`,
      formData
    );

    if (response.ok) {
      console.log("verified account");
      return "ok";
    } else {
      switch (response.status) {
        case 401:
          return "Token for user is invalid or expired";
        case 404:
          return "User doesn't exist";
        case 409:
          return "Phone number already verified with another account";
        case 500:
          return "Failed to send username retrieval code";
        default:
          return `Unexpected error occurred: ${response.status}`;
      }
    }
  } catch (error) {
    return `Error sending verification code: ${(error as Error).message}`;
  }
}

export async function sendForgotUsernameCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const options: RequestInit = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/forgot-username-request`,
      options
    );

    if (response.ok) {
      return "ok";
    } else {
      switch (response.status) {
        case 404:
          return "No verified users associated with the provided phone number";
        case 500:
          return "Server error. Try again later";
        default:
          return "Failed to send username retrieval code";
      }
    }
  } catch (error) {
    return `Error sending username retrieval code`;
  }
}

export async function sendForgotPasswordCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const options: RequestInit = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/password-reset-request`,
      options
    );

    if (response.ok) {
      console.log("reset password request");
      return "ok";
    } else {
      switch (response.status) {
        case 404:
          return "No verified users associated with the provided phone number";
        case 500:
          return "Server error. Try again later";
        default:
          return "Failed to send username retrieval code";
      }
    }
  } catch (error) {
    return `Error sending username retrieval code`;
  }
}

export default SendPhoneVerificationCode;
