// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6ZvCHDS2zoVDentDXZpaxjsnxBSIc4sc",
  authDomain: "agnsy-alpha.firebaseapp.com",
  projectId: "agnsy-alpha",
  storageBucket: "agnsy-alpha.appspot.com",
  messagingSenderId: "976533618392",
  appId: "1:976533618392:web:2f1f198a99d4f293dfa1ad",
  measurementId: "G-0VH0YBGB0T",
};
export const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);
