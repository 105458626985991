import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LogoWhite from "../../../assets/agnsy-icon.svg";
import MobileMenuIconWhite from "../../../assets/menu-icon.svg";
import MobileMenuIconBlack from "../../../assets/menu-icon-black.svg";

import "./Navbar.css";
import LogInButton from "./LogInButton";

function NavbarUnauthorized() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Prevent page scrolling when mobile menu is opened
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="navbar">
      <div className={`navbar-left-content ${isMobileMenuOpen && "active"}`}>
        <Link to="/">
          <img src={LogoWhite} className="navbar-logo-white" alt="Logo" />
        </Link>
        <div className="navbar-vertical-separator" />
        <div className="navbar-routes">
          <div className="navbar-mobile-toggle" onClick={toggleMobileMenu}>
            <img
              src={isMobileMenuOpen ? MobileMenuIconBlack : MobileMenuIconWhite}
              alt="Mobile menu toggle"
              className="navbar-mobile-toggle-icon"
            />
          </div>
          <Link to="/disclosure/upload" className="navbar-route">
            Disclosure Analysis
          </Link>
          <Link
            to="/#plans"
            className="navbar-route"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Plans
          </Link>
          <Link to="/faq" className="navbar-route">
            FAQ
          </Link>
        </div>
      </div>
      <div className="navbar-right-content">
        <Link to="/login" style={{ textDecoration: "none" }}>
          <LogInButton />
        </Link>
      </div>
    </div>
  );
}

export default NavbarUnauthorized;
