/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Data Models */

import staticDisclosurePackage from "./SampleDisclosurePackage.json";

import { PDFFile } from "../../../../model/DisclosureAnalysis/PDFFile";
import {
  AnalysisModule,
  AnalysisModuleType,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/AnalysisModule";
import PDFViewer, {
  SelectedPage,
} from "../../DisclosureAnalysis/DisclosureViewer/PDFViewer/PDFViewer";
import { ClassifiedFile } from "../../../../model/DisclosureAnalysis/ClassifiedFile";
import { ComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import { CustomComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";

/* UI Components */
import { DisclosurePackage } from "../../../../model/DisclosureAnalysis/DisclosurePackage";
import { DisclosureNavigationSection } from "../../DisclosureAnalysis/DisclosureViewer/DisclosureNavigation/DisclosureNavigationSection";
import ShareDisclosureAnalysisButton from "../../DisclosureAnalysis/DisclosureViewer/ShareDisclosureAnalysisButton";
import DisclosureNavigationToggle from "../../DisclosureAnalysis/DisclosureViewer/DisclosureNavigation/DisclosureNavigationToggle";
import DisclosureNavigationFiles from "../../DisclosureAnalysis/DisclosureViewer/DisclosureNavigation/DisclosureNavigationFiles";
import DisclosureAnalysisShimmy from "../../DisclosureAnalysis/DisclosureViewer/DisclosureViewerShimmy";
import AnalysisModuleViewer from "../../DisclosureAnalysis/DisclosureViewer/AnalysisModuleViewer/AnalysisModuleViewer";
import AnalysesNavViewer from "../../DisclosureAnalysis/DisclosureViewer/DisclosureNavigation/DisclosureNavigationAnalyses";

/* Functions and Utils */
import { openDB } from "idb";
import PDFCache from "../../../../utils/cache/pdf";
import { fetchPdfFile } from "../../DisclosureAnalysis/api";

/* Styling */
import "./DemoDisclosureViewer.css";
import CloseIcon from "../../../../assets/x.svg";
import PackageInfo from "../../DisclosureAnalysis/DisclosureViewer/PackageInfo";
import { PDFReferenceFirestore } from "../../../../model/DisclosureAnalysis/PDFReferenceFirestore";
import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const staticPdfFiles: PDFReferenceFirestore[] = [
  {
    file_id: "d460aae1-c34c-414d-8c1d-98adee0a9c8d",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "b1b223d2-6e10-43be-bb45-22829863d5dc",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "24e32765-7a8b-4805-af28-1b411ee8a0a2",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "f0d8ff64-07a9-40c9-ba3f-1cc79f446897",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "c18c6722-ecfa-43b4-8f92-25969462f178",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "c151180e-3c0e-49f1-95d5-e6733c5afdf4",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "0c5f65e2-923d-4644-8bc4-363609da1896",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "bedcb540-a4c5-4989-8801-47489a634a1b",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "3e66996c-ab20-4b5e-95de-5d21c9c08c29",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "df74e554-787c-43cb-8d9d-2e5132207c1d",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "a227185d-d75d-44e4-9b83-f807d574b77d",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
  {
    file_id: "55873e79-598f-430b-9b50-7603bb883ba5",
    uid: "842954c0-dfed-446a-aab3-7e8833abbf9f",
    disclosure_id: "c226ac0f-06bb-44ac-936a-79a7c6df30d9",
  },
];

/* COMPONENT PROPS */
interface DemoDisclosureViewerProps {
  showDemo: boolean;
  setShowDemo: (toggle: boolean) => void;
}

/* COMPONENT */
export function DemoDisclosureViewer({
  showDemo,
  setShowDemo,
}: DemoDisclosureViewerProps): JSX.Element {
  const [hovering, setHovering] = useState(false);
  const [pdfFiles, setPdfFiles] = useState<PDFFile[]>([]);
  const [pdfCache, setPdfCache] = useState<PDFCache | null>(null);

  useEffect(() => {
    const initializePDFCache = async () => {
      const db = await openDB("DisclosureAnalysis", 1, {
        upgrade(db) {
          db.createObjectStore("pdfs");
        },
      });
      setPdfCache(new PDFCache(db));
    };

    initializePDFCache();
    document.body.classList.add("dark-mode");
  }, []);

  // Prevent page scrolling when demo is being shown
  useEffect(() => {
    if (showDemo) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showDemo]);

  useEffect(() => {
    const getPDFs = async () => {
      if (pdfCache) {
        const PDFsArray = await Promise.all(
          staticPdfFiles.map(async (pdf) => {
            const cachedPDF = await pdfCache.get(pdf.file_id);
            if (cachedPDF) {
              return cachedPDF;
            } else {
              const fetchedPDF = await fetchPdfFile(pdf);
              await pdfCache.store(fetchedPDF, pdf.file_id);
              return fetchedPDF;
            }
          })
        );

        setPdfFiles(PDFsArray);
      } else {
        console.warn("No PDF Cache setup");
      }
    };

    if (pdfCache) {
      getPDFs();
    }
  }, [pdfCache]);

  if (pdfCache === null) {
    return <div>Loading...</div>;
  }
  return (
    <div className="demo-disclosure-viewer">
      {showDemo ? (
        <>
          <DemoDisclosureAnalysisViewer
            disclosurePackage={staticDisclosurePackage as DisclosurePackage}
            pdfFiles={pdfFiles}
            pdfCache={pdfCache}
            disclosureId={"68d35fb9-e7cb-47db-b31b-497627632783"}
            link={null}
            uid={null}
            closeOut={() => setShowDemo(false)}
          />
        </>
      ) : (
        <div
          className="demo-disclosure-viewer-video-container"
          onClick={() => {
            setShowDemo(true);
            setHovering(false);
          }}
          onMouseEnter={() => {
            setHovering(true);
          }}
          onMouseLeave={() => {
            setHovering(false);
          }}
        >
          <div
            style={{
              minWidth: "calc(100%)",
              height: "100%",
              minHeight: "calc(100% + 30px)",
              aspectRatio: "16/9",
              cursor: "pointer",
              borderRadius: "18px",
              overflow: "hidden",
              transform: "translateZ(0)",
            }}
          >
            <iframe
              style={{
                minWidth: "300%",
                height: "100%",
                maxHeight: "600px",
                marginLeft: "-100%",
                filter: hovering ? "blur(3px)" : "blur(0px)",
                pointerEvents: "none",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              src="https://www.youtube.com/embed/e2FpfKG0YPg?si=BClq40VMvoZtEYZT?&autoplay=1&mute=1&loop=1&controls=0&color=white&modestbranding=0&playsinline=1&enablejsapi=1&playlist=e2FpfKG0YPg"
              title="YouTube video player"
              frameBorder="0"
              referrerPolicy="strict-origin-when-cross-origin"
            />
          </div>

          {hovering && (
            <div
              className="demo-disclosure-video-hover-label"
              style={{
                opacity: hovering ? 1 : 0,
                transition: "opacity 2s ease",
                cursor: "pointer",
              }}
            >
              Try Demo
            </div>
          )}
        </div>
      )}
    </div>
  );
}

/* COMPONENT PROPS */

interface DemoDisclosureAnalysisViewerProps {
  disclosurePackage: DisclosurePackage | null;
  pdfFiles: PDFFile[];
  pdfCache: PDFCache;
  disclosureId: string | null;
  uid: string | null;
  link: string | null;
  closeOut: () => void;
}

/* COMPONENT */
export function DemoDisclosureAnalysisViewer({
  disclosurePackage,
  pdfFiles,
  pdfCache,
  disclosureId,
  uid,
  link,
  closeOut,
}: DemoDisclosureAnalysisViewerProps): JSX.Element {
  /**
   * State Variables
   */
  const [currentAnalysisModule, setCurrentAnalysisModule] =
    useState<AnalysisModule | null>(null);
  const [selectedNav, setSelectedNav] = useState<DisclosureNavigationSection>(
    DisclosureNavigationSection.ANALYSIS
  );
  const [pdfFilesToRender, setPdfFilesToRender] = useState<PDFFile[]>([]);
  const [selectedPage, setSelectedPage] = useState<SelectedPage | null>(null);
  const [isNavExpanded, setIsNavExpanded] = useState(true);

  /**
   * Type Guards
   */
  function isComponentAnalysisModule(
    module: AnalysisModule | null
  ): module is ComponentAnalysisModule {
    return module?.analysis_module_type === AnalysisModuleType.COMPONENT;
  }

  function isCustomComponentAnalysisModule(
    module: AnalysisModule | null
  ): module is CustomComponentAnalysisModule {
    return module?.analysis_module_type === AnalysisModuleType.CUSTOM_COMPONENT;
  }

  // function isMasterReportModule(
  //   module: AnalysisModule | null
  // ): module is AnalysisModule {
  //   return module?.analysis_module_type === AnalysisModuleType.MASTER_REPORT;
  // }

  /**
   * Utility Functions
   */
  const convertClassifiedFiletoPDFFile = useCallback(
    async (classifiedFiles?: ClassifiedFile[]): Promise<PDFFile[]> => {
      if (
        classifiedFiles &&
        classifiedFiles.length > 0 &&
        currentAnalysisModule &&
        pdfCache
      ) {
        const pdfs = await Promise.all(
          classifiedFiles.map(async (file) => {
            const cachedPDF = await pdfCache.get(file.pdf.file_id);
            if (cachedPDF) {
              return cachedPDF;
            } else {
              const fetchedPDF = await fetchPdfFile(file.pdf);
              await pdfCache.store(fetchedPDF, file.pdf.file_id);
              return fetchedPDF;
            }
          })
        );
        return pdfs;
      }
      return [];
    },
    [currentAnalysisModule, pdfCache]
  );

  const toggleNav = () => {
    setIsNavExpanded((prevState) => !prevState);
  };

  const getCurrentModuleName = () => {
    if (isComponentAnalysisModule(currentAnalysisModule)) {
      return currentAnalysisModule.type;
    }
    if (isCustomComponentAnalysisModule(currentAnalysisModule)) {
      return currentAnalysisModule.analysis_module_type;
    }

    return selectedNav === DisclosureNavigationSection.ANALYSIS
      ? "MASTERREPORT"
      : "Files";
  };

  /**
   * Hooks
   */
  useEffect(() => {
    const updatePDFsToRender = async () => {
      if (currentAnalysisModule === null) {
        setPdfFilesToRender([]);
        return;
      }

      // Base Component
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.COMPONENT
      ) {
        const componentModule =
          currentAnalysisModule as ComponentAnalysisModule;

        const pdfs = await convertClassifiedFiletoPDFFile(
          componentModule?.associated_files
        );
        setPdfFilesToRender(pdfs);
      }

      // Custom Component
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.CUSTOM_COMPONENT
      ) {
        const componentModule =
          currentAnalysisModule as CustomComponentAnalysisModule;

        const pdfs = await convertClassifiedFiletoPDFFile(
          componentModule?.associated_files
        );
        setPdfFilesToRender(pdfs);
      }

      // Master
      if (
        currentAnalysisModule.analysis_module_type ===
        AnalysisModuleType.MASTER_REPORT
      ) {
        setPdfFilesToRender(pdfFiles);
      }
    };

    updatePDFsToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalysisModule, pdfFiles, disclosurePackage]);

  useEffect(() => {
    setPdfFilesToRender(pdfFiles);
    if (disclosurePackage) {
      setCurrentAnalysisModule(disclosurePackage.analysis.master);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfFiles]);

  /**
   * Rendering
   */
  return (
    <div className={`disclosure-viewer-demo-wrapper`}>
      <img
        className="demo-disclosure-close-demo-button"
        src={CloseIcon}
        alt="Try again."
        onClick={() => closeOut()}
      />
      <div className="disclosure-viewer-demo">
        {disclosurePackage ? (
          <>
            <div className="disclosure-viewer-header">
              <PackageInfo
                propertyName={disclosurePackage.property_name}
                disclosureID={disclosurePackage.id}
              />
              <ShareDisclosureAnalysisButton
                uid={uid}
                disclosure_id={disclosureId}
                share_link={window.location.href}
              />
            </div>
            <div className="disclosure-viewer-viewers">
              <div className="disclosure-viewer-viewers-analysis">
                <div
                  className={`disclosure-viewer-navigation ${
                    isNavExpanded ? "expanded" : "collapsed"
                  }`}
                  onClick={toggleNav}
                >
                  <div
                    className={`disclosure-viewer-selected-section ${getCurrentModuleName()}`}
                  >
                    {getCurrentModuleName() === "MASTERREPORT"
                      ? "Master Report"
                      : ComponentTypeDisplayMapping[
                          getCurrentModuleName() as ComponentAnalysisType
                        ]}
                  </div>
                  <>
                    <DisclosureNavigationToggle
                      selected={selectedNav}
                      setSelected={setSelectedNav}
                    />
                    {selectedNav === DisclosureNavigationSection.ANALYSIS ? (
                      <AnalysesNavViewer
                        disclosureID={disclosurePackage.id}
                        masterReport={disclosurePackage.analysis.master}
                        components={Object.values(
                          disclosurePackage.analysis.components
                        )}
                        customAnalyses={Object.values(
                          disclosurePackage.analysis.custom_analyses
                        )}
                        currentAnalysisModule={currentAnalysisModule}
                        setCurrentAnalysisModule={setCurrentAnalysisModule}
                      />
                    ) : (
                      <DisclosureNavigationFiles pdfs={pdfFiles} />
                    )}
                  </>
                </div>
                <AnalysisModuleViewerWrapper
                  disclosurePackage={disclosurePackage}
                  currentAnalysisModule={currentAnalysisModule}
                  pdfCache={pdfCache}
                  setSelectedPage={setSelectedPage}
                />
              </div>
              <PDFViewer pdfs={pdfFilesToRender} selected={selectedPage} />
            </div>
          </>
        ) : (
          <DisclosureAnalysisShimmy />
        )}
      </div>
    </div>
  );
}

function AnalysisModuleViewerWrapper({
  disclosurePackage,
  currentAnalysisModule,
  pdfCache,
  setSelectedPage,
}: {
  disclosurePackage: DisclosurePackage;
  currentAnalysisModule: AnalysisModule | null;
  pdfCache: PDFCache;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const selectedComponent =
    currentAnalysisModule === null
      ? disclosurePackage.analysis.master
      : currentAnalysisModule;

  return (
    <div className="disclosure-viewer-component-analysis">
      <AnalysisModuleViewer
        disclosurePackage={disclosurePackage}
        analysisModule={selectedComponent}
        pdfCache={pdfCache}
        setSelectedPage={setSelectedPage}
      />
    </div>
  );
}
