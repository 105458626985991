import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Data Models
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";

// UI Components
import DashboardSideNav from "./DashboardSideNav/DashboardSideNav";
import Navbar from "../../Common/Navbar";

// Functions and Utils
import { Auth, getWithAuth } from "../../../firebase/authentication/auth";
import { startAnalysis } from "../DisclosureUpload/api";
import { withRequirements, userSignedInRequirement } from "../../Requirements";

// Styling
import "./Dashboard.css";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

function Dashboard(): JSX.Element {
  /**
   * Consts
   */
  const navigate = useNavigate();

  /**
   * State Vars
   */
  const [packages, setPackages] = useState<DisclosurePackage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<Record<string, boolean>>({});

  /**
   * Hooks
   */
  useEffect(() => {
    const fetchPackages = async () => {
      if (Auth.currentUser) {
        const fetchedNames = await fetchDisclosurePackages(
          Auth.currentUser.uid
        );
        setPackages(fetchedNames);
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setUploading(true);
      startAnalysis(selectedFile, (event) => {})
        .then((disclosureId) => {
          navigate(`/disclosure/analysis?disclosure=${disclosureId}`);
        })
        .catch((error) => {
          console.error("Error starting analysis:", error);
          setSelectedFile(null);
          setUploading(false);
        });
    }
  }, [selectedFile, navigate]);

  const fetchDisclosurePackages = async (
    uid: string
  ): Promise<DisclosurePackage[]> => {
    const url = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/all`;
    try {
      const response = await getWithAuth(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.disclosures as DisclosurePackage[];
    } catch (error) {
      console.error("Fetching disclosure names failed:", error);
      return [];
    }
  };

  const handleDelete = async (id: string) => {
    // Set the deleting status for the package
    setDeleting((prev) => ({ ...prev, [id]: true }));
    const url = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/${id}`;
    try {
      const authToken = await Auth.currentUser?.getIdToken();
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setPackages((prev) => prev.filter((pkg) => pkg.id !== id));
    } catch (error) {
      console.error("Deleting disclosure failed:", error);
    } finally {
      // Reset the deletion status after completion
      setDeleting((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleDisclosureClick = (
    id: string,
    location: { lat: number; lng: number }
  ) => {
    navigate(`/disclosure/analysis?disclosure=${id}`);
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <div className="dashboard">
          <DashboardSideNav />
          <div className="dashboard-body">
            <div className="dashboard-body-title">Disclosure Analyses</div>
            <Link
              to="/disclosure/upload"
              className="dashboard-new-disclosure-button"
            >
              New
            </Link>
            {isLoading || uploading ? (
              <div className="spinner-container">
                <div className="spinner" />
              </div>
            ) : packages.length === 0 ? (
              <div style={{ marginTop: "30px" }}>
                No disclosure packages yet.
              </div>
            ) : (
              <div className="dashboard-content">
                <div className="dashboard-disclosures-list">
                  {packages.map(({ id, property_name }) => (
                    <div className="dashboard-disclosures-list-item" key={id}>
                      <span
                        className="dashboard-disclosures-list-item-name"
                        onClick={() =>
                          handleDisclosureClick(id, { lat: 37.39, lng: 122.08 })
                        }
                      >
                        {property_name}
                      </span>
                      {deleting[id] ? (
                        <span className="small-spinner"></span>
                      ) : (
                        <button
                          className="trash-btn"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the disclosure click
                            const user = Auth.currentUser;
                            if (user) {
                              handleDelete(id);
                            }
                          }}
                        ></button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Dashboard, [userSignedInRequirement]);
