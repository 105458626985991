import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

import footerLogo from "../../../assets/agnsy_logo_white.svg";
import instagramLogo from "../../../assets/instagram.svg";
import twitterLogo from "../../../assets/twitter.svg";
import youtubeLogo from "../../../assets/youtube.svg";

const Footer: React.FC = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-column">
            <img src={footerLogo} alt="footer-logo" />
          </div>

          <div className="footer-column">
            <ul>
              <li className="footer-subtitle">Product</li>
              <li className="footer-text">
                <Link to="/disclosure/upload">Disclosure Analysis</Link>
              </li>
              <li className="footer-text">
                <Link to="/#plans">Plans</Link>
              </li>
              <li className="footer-text">
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <ul>
              <li className="footer-subtitle">Company</li>
              <li className="footer-text">
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>
              <li className="footer-text">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="footer-text">
                <Link to="/faq#contact">Contact</Link>
              </li>
              <li className="footer-text">
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-socials">
          <Link to=".https://www.instagram.com/agnsyai">
            <img src={instagramLogo} alt="Instagram" />
          </Link>
          <Link to="https://x.com/agnsyai">
            <img src={twitterLogo} alt="Twitter" />
          </Link>
          <Link to="https://www.youtube.com/@Agnsyai">
            <img src={youtubeLogo} alt="YouTube" />
          </Link>
        </div>

        <div className="footer-disclaimer">
          Disclaimer: due to the nature of artificial intelligence advancing
          technology, we advise you to review any output for errors. We assume
          no responsibility for failure of this by the user.
        </div>
        <div className="footer-copyright">&copy; Agnsy 2024</div>
      </footer>

      {/* Mobile */}
      <footer className="footer-mobile">
        <div className="footer-mobile-imgs">
          <div className="footer-mobile-logo">
            <img src={footerLogo} alt="footer-logo" />
          </div>

          <div className="footer-mobile-socials">
            <Link to="https://www.instagram.com/">
              <img src={instagramLogo} alt="Instagram" />
            </Link>
            <Link to="https://x.com/">
              <img src={twitterLogo} alt="Twitter" />
            </Link>
            <Link to="https://www.youtube.com/">
              <img src={youtubeLogo} alt="YouTube" />
            </Link>
          </div>
        </div>

        <div className="footer-mobile-body">
          <div className="footer-mobile-column">
            <ul>
              <li className="footer-mobile-subtitle">Product</li>
              <li className="footer-mobile-text">
                <Link to="/disclosure/upload">Disclosure Analysis</Link>
              </li>
              <li className="footer-mobile-text">
                <Link to="/#plans">Plans</Link>
              </li>
              <li className="footer-mobile-text">
                <Link to="/FAQ">FAQ</Link>
              </li>
            </ul>
          </div>

          <div className="footer-mobile-column">
            <ul>
              <li className="footer-mobile-subtitle">Company</li>
              <li className="footer-mobile-text">
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>
              <li className="footer-mobile-text">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="footer-mobile-text">
                <Link to="/faq#contact">Contact</Link>
              </li>
              <li className="footer-mobile-text">
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-mobile-disclaimer">
          Disclaimer: Due to the nature of artificial intelligence advancing
          technology, we advise you to review any output for errors. We assume
          no responsibility for failure of this by the user.
        </div>
        <div className="footer-mobile-copyright">&copy; Agnsy 2024</div>
      </footer>
    </div>
  );
};

export default Footer;
